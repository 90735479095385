/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

const selector = '.js-hamburger';
const activeClass = 'is-active';
const bodyActiveClass = 'h-scroll-lock nav-active';
const headerActiveClass = 'o-header-1--hamburger-active';
const navMenu = '.js-slide-out';
const navLink = '.js-nav-link';
const navMenuActiveClass = 'm-slide-out--active';
const headerSearchButton = 'o-header__search-button';
const searchBar = 'm-search-bar';

// Declaring common variables
let isActive;
const linksAndButtons = $(`${navMenu} a, ${navMenu} button`); // All <a> and <button> elements inside the navMenu

export function hamburger() {
    $(selector).click((e) => {
        e.preventDefault();
        $(selector).toggleClass(activeClass);

        // Ensuring body cannot be scrolled up and down when the mobile nav is shown
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);

        // Makes slide-out appear
        $(navMenu).toggleClass(navMenuActiveClass);

        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }

        $('.js-header-top').slideToggle(250);
    });

    // Ensuring slideout nav menu is next element focused after hamburger toggle button
    $(selector).keydown(function (e) {
        if (e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (isActive) {
                e.preventDefault();
                linksAndButtons.first()[0].focus(); // [0] gets the DOM element from the jQuery object
            }
        }
    });

    // Ensuring focus shifts back to hamburger toggle button when going back
    linksAndButtons.first().keydown(function (e) {
        if (e.shiftKey && e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (
                isActive &&
                document.activeElement === linksAndButtons.first()[0]
            ) {
                e.preventDefault();
                $(selector)[0].focus(); // [0] gets the DOM element from the jQuery object
            }
        }
    });

    // Ensuring focus shifts back to hamburger toggle button after last link in slide out nav menu
    linksAndButtons.last().keydown(function (e) {
        if (e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (
                isActive &&
                document.activeElement === linksAndButtons.last()[0]
            ) {
                e.preventDefault();
                $(selector)[0].focus(); // [0] gets the DOM element from the jQuery object
            }
        }
    });

    $(window).on('resize', () => {
        if ($(window).width() >= 1140) {
            if ($('.o-header-1__hamburger').hasClass('is-active')) {
                $('.o-header-1__hamburger').trigger('click');
            }
        }
    });
}

export function hamburgerClose() {
    $(selector).removeClass(activeClass);
    $('.js-body').removeClass(bodyActiveClass);
    $('.js-header').removeClass(headerActiveClass);
    $(navMenu).removeClass(navMenuActiveClass);
}
