function widgetTranslation() {
    // Language Widget Blocks

    var widget = '.js-widget-translation';
    var toggler = '.js-widget-translation-toggler';
    var headerBtn = '.js-widget-translation-header';

    $(toggler).on('click keydown', function () {
        // Check if the event is a click or a keydown event with the Enter key (key code 13 or key 'Enter')
        if (
            event.type === 'click' ||
            event.which === 13 ||
            event.keyCode === 13 ||
            event.key === 'Enter'
        ) {
            if (
                event.type === 'keydown' &&
                event.key !== 'Enter' &&
                event.which !== 13 &&
                event.keyCode !== 13
            ) {
                return; // If it's a keydown event but not the Enter key, exit the function
            }

            var widget_obj = $(this).closest(widget);

            const languageSwitchersBlocks = widget_obj.find(
                '.js-google-translate-switcher',
            );

            if (widget_obj.hasClass('active')) {
                widget_obj.removeClass('active');
                // Removing tabindex attribute from languages so that they are not tabbable when widget is closed
                languageSwitchersBlocks.each(function () {
                    const anchorTags = this.querySelectorAll('a');
                    anchorTags.forEach((tag) => {
                        tag.removeAttribute('tabindex');
                    });
                });
            } else {
                widget_obj.addClass('active');
                // Making sure languages receive tabindex attribute when widget is open in order to access them with keyboard
                languageSwitchersBlocks.each(function () {
                    const anchorTags = this.querySelectorAll('a');
                    anchorTags.forEach((tag) => {
                        tag.setAttribute('tabindex', '0');
                    });
                });
            }
        }
    });

    $(headerBtn).on('click', function (e) {
        e.preventDefault();
        $(toggler).trigger('click');
    });

    $(document).on('click', function (e) {
        if (
            !$(e.target).closest(widget).length &&
            $(widget).hasClass('active') &&
            !$(e.target).closest(headerBtn).length
        ) {
            $(widget).removeClass('active');
        }
    });

    const languageSwitchersBlocks = document.querySelectorAll(
        `.js-google-translate-switcher`,
    );

    if (languageSwitchersBlocks.length) {
        // Looping through all of them (we can have several of them)
        [...languageSwitchersBlocks].map((languageSwitcherBlock) => {
            // Adding click and keydown listeners
            languageSwitcherBlock.addEventListener(
                'click',
                handleLanguageSwitch,
            );
            languageSwitcherBlock.addEventListener(
                'keydown',
                handleLanguageSwitch,
            );
        });
    }

    function handleLanguageSwitch(event) {
        // Check if the event is a click or a keydown event with the Enter key (key code 13 or key 'Enter')
        if (
            event.type === 'click' ||
            event.which === 13 ||
            event.keyCode === 13 ||
            event.key === 'Enter'
        ) {
            if (
                event.type === 'keydown' &&
                event.key !== 'Enter' &&
                event.which !== 13 &&
                event.keyCode !== 13
            ) {
                return; // If it's a keydown event but not the Enter key, exit the function
            }

            const target = event.target;
            const button = target.closest('span');

            if (!button) {
                return;
            }

            event.preventDefault();

            const chosenLanguage = button.getAttribute('data-lang');
            if (!chosenLanguage) {
                return;
            }

            const googleTranslateSelect =
                document.querySelector('.goog-te-combo');
            if (!googleTranslateSelect) {
                return;
            }

            googleTranslateSelect.value = chosenLanguage;
            googleTranslateSelect.dispatchEvent(new Event('change'));
        }
    }
}

export default widgetTranslation;
