function glossaryA1() {
    const hooks = {
        languageSelector: '[data-glossary-select]',
        glossaryTab: '[data-glossary-tab]',
    };

    const $languageSelector = $(hooks.languageSelector);

    if (!$languageSelector.length) {
        return;
    }

    // Add custom property to language selector, it allows us to get choices.js instance
    document.querySelector(hooks.languageSelector).choices = new Choices(
        hooks.languageSelector,
        {
            searchEnabled: false,
            itemSelectText: '',
        },
    );

    $languageSelector.on('change', function () {
        const selectedOptionValue = $(this).val();

        $(hooks.glossaryTab).each(function () {
            const $el = $(this);

            if ($el.data('glossary-tab') === selectedOptionValue) {
                $el.removeClass('hidden');
            } else {
                if (!$el.hasClass('hidden')) {
                    $el.addClass('hidden');
                }
            }
        });
    });
}

export default glossaryA1;
