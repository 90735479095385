import Swiper, { Autoplay } from 'swiper';

function hero2() {
    Swiper.use([Autoplay]);

    // Hero Type 2 Slider play-pause logic
    const hero2Slider = document.querySelector(
        '.o-hero-2 .swiper-container',
    ).swiper;
    let isSliderActive = true;

    $('.o-hero-2__slider-control').click(function () {
        if (isSliderActive) {
            hero2Slider.autoplay.stop();
            isSliderActive = false;
        } else {
            hero2Slider.autoplay.start();
            isSliderActive = true;
        }

        $(this).toggleClass('paused');
    });
}

export default hero2;
