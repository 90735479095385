function showAccordion() {
    const classes = {
        block: 'm-accordion',
        group: 'm-accordion__group',
        link: 'm-accordion__heading-link',
        text: 'm-accordion__text-wrapper',
        title: 'm-accordion__title',
        track: 'm-accordion__track',
        verticalControl: 'm-accordion__control--vertical',
        active: 'is-active',
    };

    $(`.${classes.link}`).click(function (e) {
        const group = $(e.target).closest(`.${classes.group}`);
        e.preventDefault();

        /**
         * Disable scroll page to #hash on click
         * Remove Hash from URL when close Accordion
         **/
        let newURL = '';
        if (group.hasClass(classes.active)) {
            const pageHashTarget = window.location.hash;
            newURL = window.location.href.replace(`${pageHashTarget}`, '');
        } else {
            newURL = e.currentTarget.href;
        }
        window.history.pushState({ path: newURL }, '', newURL);

        /**
         * Toggles the state of an accordion group.
         */
        const text = group.find(`.${classes.text}`);
        const controlVertical = group.find(`.${classes.verticalControl}`);
        const title = group.find(`.${classes.title}`);
        const track = group.find(`.${classes.track}`);

        group.toggleClass(classes.active);
        controlVertical.toggleClass('rotate-90');
        title.toggleClass('text-brand-color-2-d');
        track.toggleClass('!bg-brand-color-2-d');
        // eslint-disable-next-line jquery/no-slide
        text.slideToggle(300);
    });

    /**
     * Open accordion if url-hash exists
     **/
    (function () {
        const anchor = location.hash
            ? document.querySelector(location.hash)
            : '';
        if (!anchor) {
            return;
        }
        if (!anchor.closest('[data-module="showAccordion"]')) {
            return;
        }

        const group = $(anchor).closest(`.${classes.group}`);
        const link = group.find(`.${classes.link}`);
        setTimeout(function () {
            link.click();
        }, 700);
    })();
}

export default showAccordion;
