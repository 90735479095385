import Cookies from 'js-cookie';

import Helpers from '/src/global/js/helpers/helpers.js';

function sidebarPostsA1() {
    /**
     * Initialize module.
     * @param module
     */
    const moduleInit = function (module) {
        setTaxGeographyTerm(module);
        showAlmBlock(module);
        triggerClick(module);

        // Listener for detecting empty ALM result
        window.almEmpty = function () {
            nothingFound(module);
        };
    };

    /**
     * Update button attributes based on the provided geography parameter.
     * @param {HTMLElement} button - The button element to update.
     * @param {string} geographyParam - The geography parameter value.
     */
    function setTaxGeographyTerm(module) {
        const listing = getAlmListing(module);
        if (!listing) {
            return false;
        }

        const geographyTerm = getGeographyTerm();

        if (geographyTerm) {
            if (listing.dataset.taxonomyTerms) {
                listing.dataset.taxonomy += ':geography';
                listing.dataset.taxonomyTerms += ':' + geographyTerm;
                listing.dataset.taxonomyOperator += ':IN';
            } else {
                listing.dataset.taxonomy = 'geography';
                listing.dataset.taxonomyTerms = geographyTerm;
                listing.dataset.taxonomyOperator = 'IN';
            }
        }
    }

    /**
     * Load Related posts by triggering AJAX Load More button.
     * @param module
     */
    const triggerClick = function (module) {
        const button = getListingButton(module);
        if (button) {
            button.click();
        }
    };

    /**
     * Get the geography parameter from the URL or cookies.
     * @returns {string} The geography parameter value.
     */
    function getGeographyTerm() {
        // Get Url Param for Taxonomy Geography term slug
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('geography')) {
            return urlParams.get('geography');
        } else if (Cookies.get('selected_trerc_geography')) {
            return Cookies.get('selected_trerc_geography');
        } else {
            return false;
        }
    }

    /**
     * Get listing container element.
     * @param module
     * @return {null|HTMLElement}
     */
    const getAlmListing = function (module) {
        return module.querySelector('.alm-listing');
    };

    /**
     * Get Load button element.
     * @param module
     * @return {null|HTMLElement}
     */
    const getListingButton = function (module) {
        return module.querySelector('.alm-load-more-btn');
    };

    /**
     * Handle the case for showing alm block and clearing notice
     *
     * @param {HTMLElement} module - The module element containing the Ajax Load More instance.
     */
    function showAlmBlock(module) {
        var almBlock = module.querySelector('.ajax-load-more-wrap');
        if (almBlock) {
            almBlock.style.display = 'block';
            var notice = module.querySelector('.js-notice');
            if (notice) {
                notice.remove();
            }
        }
    }

    /**
     * Handle the case when nothing is found in an Ajax Load More query.
     *
     * @param {HTMLElement} module - The module element containing the Ajax Load More instance.
     */
    function nothingFound(module) {
        var almBlock = module.querySelector('.ajax-load-more-wrap');
        if (almBlock) {
            almBlock.style.display = 'none';
            var notice = '<div class="js-notice">No relevant posts found</div>';
            almBlock.insertAdjacentHTML('afterend', notice);
        }
    }

    (function () {
        document
            .querySelectorAll('[data-module="sidebarPostsA1"]')
            .forEach(function (item) {
                moduleInit(item);
            });
    })();
}
export default sidebarPostsA1;
