function feedbackA() {
    tippy(document.querySelector('.pld-like-trigger'), {
        content: 'Like',
        duration: 600,
        animation: 'shift-toward',
        theme: 'like',
    });

    tippy(document.querySelector('.pld-dislike-trigger'), {
        content: 'Dislike',
        duration: 600,
        animation: 'shift-toward',
        theme: 'dislike',
    });
}

export default feedbackA;
