/**
 * Used to show parallax effect in heros. Does NOT work if used for sections below the hero
 */

function parallaxScroll($) {
    const scrollDistance = $(window).scrollTop();
    const $container = $('.js-parallax');
    const opacityGroup = '.js-parallax__opacity';
    const translateGroup = '.js-parallax__translate';

    // Check if .js-parallax has the .js-no-mobile-parallax class
    const disableParallaxMobile = $container.hasClass('js-no-mobile-parallax');

    // Check if the device supports touch events and has a small screen width
    const isMobile =
        ('ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0) &&
        $(window).width() < 768;

    // If the device is not mobile, apply parallax effects
    if (!(isMobile && disableParallaxMobile)) {
        // To Do: Add Modernizr
        // if (!Modernizr.touch) {
        // alter opacity
        $container.find(opacityGroup).css('opacity', 1 - scrollDistance / 500);

        // alter scrolling speed

        $container
            .find(translateGroup)
            .css(
                'transform',
                `translate3d(0px,${0.2 * scrollDistance}px, 0px)`,
            );
        // }
    }
}

module.exports = parallaxScroll;
