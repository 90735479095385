import Cookies from 'js-cookie';

import Helpers from '/src/global/js/helpers/helpers.js';

function latestPostsA6() {
    /**
     * Initialize module.
     * @param module
     */
    const moduleInit = function (module) {
        setTaxPersonaTerms(module);
        triggerClick(module);
    };

    const setTaxPersonaTerms = function (module) {
        const listing = getAlmListing(module);
        if (!listing) {
            return false;
        }

        const personaPosts = getPersonaPosts(module);
        const personaTerm = getPersonaTermCookie();

        if (personaPosts[personaTerm]) {
            listing.dataset.postIn = personaPosts[personaTerm].join(', ');
            console.log(`Render Popular Posts of Cookie '${personaTerm}'.`);
            console.log(`Popular Posts: '${personaPosts[personaTerm]}'.`);
            return true;
        }

        console.log(
            'No Tax Persona Terms selected in Coolie for Popular Posts',
        );
    };

    /**
     * Load Related posts by triggering AJAX Load More button.
     * @param module
     */
    const triggerClick = function (module) {
        const button = getListingButton(module);
        if (button) {
            button.click();
        }
    };

    /**
     * Get Cookie for Taxonomy Persona term slug
     * @return {string}
     */
    const getPersonaTermCookie = function () {
        return Cookies.get('selected_trerc_persona');
    };

    /**
     * Get listing container element.
     * @param module
     * @return {null|HTMLElement}
     */
    const getAlmListing = function (module) {
        return module.querySelector('.alm-listing');
    };

    /**
     * Get Load button element.
     * @param module
     * @return {null|HTMLElement}
     */
    const getListingButton = function (module) {
        return module.querySelector('.alm-load-more-btn');
    };

    /**
     * Retrieve an array of popular posts for the Persona taxonomy, sorted by terms within that taxonomy.
     * @param module {HTMLElement}
     * @return object
     */
    const getPersonaPosts = function (module) {
        const popularPostsContainer = module.querySelector('.js-popular-posts');
        if (!popularPostsContainer) {
            return {};
        }
        return Helpers.getJsonFromAttr(
            popularPostsContainer.dataset.personaPopularPosts,
        );
    };

    /**
     * Init module
     */
    (function () {
        document
            .querySelectorAll('[data-module="latestPostsA6"]')
            .forEach(function (item) {
                moduleInit(item);
            });
    })();
}
export default latestPostsA6;
