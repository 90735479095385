import Cookies from 'js-cookie';

class Help1 {
    /* global helpSecondOptions */
    /**
     * Root element
     *
     * @type HTMLElement
     */

    rootElement;

    /**
     * Second Options
     *
     * @type {object}
     */

    secondOptions;

    /**
     * First select selector
     * @type {String}
     */
    HELP_FIRST_SELECT_SELECTOR = '.js-first-select';

    /**
     * First select element
     * @type {HTMLElement}
     */
    firstSelect;

    /**
     * Name of the Cookie to save fist select value
     * @type {String}
     */
    COOKIE_FIRST_SELECT = 'selected_trerc_persona';

    /**
     * First second selector
     * @type {String}
     */
    HELP_SECOND_SELECT_SELECTOR = '.js-second-select';

    /**
     * Second select element
     * @type {HTMLElement}
     */
    secondSelect;

    /**
     * First geo selector
     * @type {String}
     */
    HELP_GEO_SELECT_SELECTOR = '.js-geo-select';

    /**
     * Geo taxonomy select element
     * @type {HTMLElement}
     */
    geoSelect;

    /**
     * Name of the Cookie to save geo value
     * @type {String}
     */
    COOKIE_GEO_SELECT = 'selected_trerc_geography';

    /**
     * Submit button selector
     * @type {String}
     */
    HELP_SUBMIT_SELECTOR = '.js-help-submit';

    /**
     * Submit button element
     * @type {HTMLButtonElement}
     */
    submitButton;

    /**
     * First select Choice element
     * @type Choice
     */

    firstChoice;

    /**
     * Second select Choice element
     * @type Choice
     */

    secondChoice;

    /**
     * Geo select Choice element
     * @type Choice
     */

    geoChoice;

    /**
     *
     * @param {HTMLElement} element
     */
    constructor(element) {
        // Store a reference to the Module's HTML element.
        this.rootElement = element;

        // Store the Global variable locally.
        if (helpSecondOptions) {
            this.secondOptions = helpSecondOptions;
        } else {
            this.secondOptions = [];
        }

        // Actions.
        this._setChildElements();
        this._configureChoicesJS();
        this._initializeEventHandlers();

        // Set the Persona from a Cookie as the default selected value.
        if (Cookies.get(this.COOKIE_FIRST_SELECT)) {
            this.firstChoice.setChoiceByValue(
                Cookies.get(this.COOKIE_FIRST_SELECT),
            );
            // Trigger the 'change' event forcefully to allow other handlers to proceed.
            this.firstSelect.dispatchEvent(new Event('change'));
        }

        // Set the Geography from a Cookie as the default selected value.
        if (Cookies.get(this.COOKIE_GEO_SELECT)) {
            this.geoChoice.setChoiceByValue(
                Cookies.get(this.COOKIE_GEO_SELECT),
            );
            // Trigger the 'change' event forcefully to allow other handlers to proceed.
            this.geoSelect.dispatchEvent(new Event('change'));
        }

        // Disable/Enable selects.
        this._manageInteractiveElementsState();

        //
        this._initializeDataLayerEvents();
    }

    /**
     * Store references to the HTML elements for the Module.
     * @private
     */
    _setChildElements() {
        this.firstSelect = this.rootElement.querySelector(
            this.HELP_FIRST_SELECT_SELECTOR,
        );
        this.secondSelect = this.rootElement.querySelector(
            this.HELP_SECOND_SELECT_SELECTOR,
        );
        this.geoSelect = this.rootElement.querySelector(
            this.HELP_GEO_SELECT_SELECTOR,
        );
        this.submitButton = this.rootElement.querySelector(
            this.HELP_SUBMIT_SELECTOR,
        );
    }

    /**
     * Enable Choices JS for element selection.
     * @private
     */
    _configureChoicesJS() {
        if (this.firstSelect) {
            this.firstChoice = new Choices(this.firstSelect, {
                searchEnabled: false,
                searchChoices: false,
                allowHTML: true,
                itemSelectText: '',
                shouldSort: false,
            });
        }
        if (this.secondSelect) {
            this.secondChoice = new Choices(this.secondSelect, {
                searchEnabled: false,
                searchChoices: false,
                allowHTML: true,
                itemSelectText: '',
                shouldSort: false,
            });
        }
        if (this.geoSelect) {
            this.geoChoice = new Choices(this.geoSelect, {
                searchEnabled: false,
                searchChoices: false,
                allowHTML: true,
                itemSelectText: '',
                shouldSort: false,
                shouldSortItems: false,
            });
        }
    }

    /**
     * Hanging events on elements.
     * @private
     */
    _initializeEventHandlers() {
        if (this.firstChoice) {
            this.firstChoice.passedElement.element.addEventListener(
                'change',
                this._handleFirstChoiceChangeEvent.bind(this),
            );
        }

        if (this.secondChoice) {
            this.secondChoice.passedElement.element.addEventListener(
                'change',
                this._manageInteractiveElementsState.bind(this),
            );
        }

        if (this.geoChoice) {
            this.geoChoice.passedElement.element.addEventListener(
                'change',
                this._handleGeoChoiceChangeEvent.bind(this),
            );
        }

        if (this.submitButton) {
            this.submitButton.addEventListener(
                'click',
                this._handleSubmitClickEvent.bind(this),
            );
        }
    }

    /**
     * Handle the Change Event for the First Choices.
     * @private
     */
    _handleFirstChoiceChangeEvent() {
        const value = this._getFirstChoiceValue();

        if (value) {
            Cookies.set(this.COOKIE_FIRST_SELECT, value, {
                expires: 365,
            });

            this._populateSecondSelectElement();
        } else {
            Cookies.remove(this.COOKIE_FIRST_SELECT);
        }
        //
        this._manageInteractiveElementsState();
    }

    /**
     * Handle the Change Event for the Geo Choices.
     * @private
     */
    _handleGeoChoiceChangeEvent() {
        const value = this._getGeoChoiceValue();

        if (value) {
            Cookies.set(this.COOKIE_GEO_SELECT, value, {
                expires: 365,
            });
        } else {
            Cookies.remove(this.COOKIE_GEO_SELECT);
        }
    }

    /**
     * Handles the click event when the form is submitted.
     *
     * @param {Event} e - The event object representing the form submission.
     * @return {boolean|void}
     */
    _handleSubmitClickEvent(e) {
        e.preventDefault();

        const secondValue = this._getSecondChoiceValue();
        const secondOption = this._getSecondChoiceOption();

        if (!secondValue) {
            // Exit if secondValue is not available.
            return;
        }

        // Initialize variable for geography value
        const geoValue = this._getGeoChoiceValueLabel();

        if (!geoValue) {
            // Redirect to the second value URL.
            window.location.href = secondValue;
            return;
        }

        // Create a URL object from the second value
        const url = new URL(secondValue);
        let queryStr = url.search;

        // Check if additional query parameters should be added
        const addFilterQuery =
            secondOption['query_parameters'].includes('filter');
        const addDataPageQuery =
            secondOption['query_parameters'].includes('data-page');
        const addLatestPostsQuery =
            secondOption['query_parameters'].includes('latest-posts');
        let filterQuery = '';
        let dataPageQuery = '';
        let latestPostsQuery = '';

        if (addFilterQuery) {
            filterQuery = this._generateFilterQueryString(queryStr, geoValue);
        }

        if (addDataPageQuery) {
            const dataPageQueryType = secondOption['data_page_type'];
            dataPageQuery = this._generateDataPageQueryString(
                dataPageQueryType,
                geoValue,
            );
        }

        if (addLatestPostsQuery) {
            latestPostsQuery = this._generateLatestPostsQueryString(geoValue);
        }

        if (filterQuery) {
            queryStr = queryStr
                ? `${queryStr}&${filterQuery}`
                : `?${filterQuery}`;
        }

        if (dataPageQuery) {
            queryStr = queryStr
                ? `${queryStr}&${dataPageQuery}`
                : `?${dataPageQuery}`;
        }

        if (latestPostsQuery) {
            queryStr = queryStr
                ? `${queryStr}&${latestPostsQuery}`
                : `?${latestPostsQuery}`;
        }
        url.search = queryStr;
        // Redirect to the constructed URL.
        window.location.href = url.toString();
    }

    /**
     * Handles the submission of data to the dataLayer.
     * This function checks whether the form's submit button is enabled, then pushes
     * relevant persona, motive, and geography selections to the dataLayer for tracking purposes.
     *
     * @private
     */
    _handleDataLayerTracking() {
        // Check if the submit button is disabled to prevent submission if necessary
        if (this.submitButton.hasAttribute('disabled')) {
            return;
        }

        // Construct the data object to be sent to the dataLayer
        const dataLayerArray = {
            event: 'persona_filter_selected',
            persona: this._getFirstChoiceValue(),
            motive: this._getSecondChoiceValue('label'),
            geography: this._getGeoChoiceValueLabel(),
        };
        console.log(dataLayerArray);

        // Check if dataLayer exists and is an object before attempting to push
        if (typeof window.dataLayer === 'object') {
            window.dataLayer.push(dataLayerArray);
        }
    }

    /**
     * Add the options for the Second Choices.
     * @return {boolean}
     * @private
     */
    _populateSecondSelectElement() {
        const key = this._getFirstChoiceValue();

        this.secondChoice.clearChoices();

        if (this.secondOptions[key] === undefined) {
            return false;
        }

        this.secondOptions[key].forEach((item, index) => {
            this.secondChoice.setChoices([
                {
                    value: item.option_url,
                    label: item.option_text,
                    selected: 0 === index,
                },
            ]);
        });
        return true;
    }

    /**
     * Toggle the State of Interactive Elements (Enable/Disable).
     * @private
     */
    _manageInteractiveElementsState() {
        //
        const firstValue = this._getFirstChoiceValue();
        if (firstValue) {
            this.secondChoice.enable();
        } else {
            this._resetSecondSelectElement();
            this.secondChoice.disable();
        }
        //
        const secondOption = this._getSecondChoiceOption();
        if (secondOption['show_geography_dropdown']) {
            this.geoChoice.enable();
        } else {
            this._resetGeoSelectElement();
            this.geoChoice.disable();
        }
        //
        const secondValue = this._getSecondChoiceValue();
        if (secondValue) {
            this.submitButton.removeAttribute('disabled');
        } else {
            this.submitButton.setAttribute('disabled', 'true');
        }
    }

    /**
     * Add Event Listeners to trigger dataLayer analytics
     * Push dataLayer each select change
     **/
    _initializeDataLayerEvents() {
        if (typeof window.dataLayer === 'object') {
            // add listener for the first choice
            if (this.firstChoice) {
                this.firstChoice.passedElement.element.addEventListener(
                    'change',
                    this._handleDataLayerTracking.bind(this),
                );
            }

            // add listener for the second choice
            if (this.secondChoice) {
                this.secondChoice.passedElement.element.addEventListener(
                    'change',
                    this._handleDataLayerTracking.bind(this),
                );
            }

            // add listener for the geo choice
            if (this.geoChoice) {
                this.geoChoice.passedElement.element.addEventListener(
                    'change',
                    this._handleDataLayerTracking.bind(this),
                );
            }
        } else {
            console.log('"dataLayer" object is absent');
        }
    }

    /**
     * Remove the Second Choices options
     * @private
     */
    _resetSecondSelectElement() {
        this.secondChoice.clearChoices();
        this.secondChoice.setChoices([
            {
                label: 'I want to...',
                id: 1,
                value: '',
                selected: true,
                disabled: true,
            },
        ]);
    }

    _resetGeoSelectElement() {
        // value="" for the placeholder option.
        this.geoChoice.setChoiceByValue('');
    }

    /**
     * Make the query string for Typesense
     * paramQuery.set() method don't used as the string is coded wrong for the Typesense
     * @param queryStr
     * @param value
     * @return {string}
     * @private
     */
    _generateFilterQueryString(queryStr = '', value) {
        if (!value) {
            return '';
        }
        // get post type to set to Typesense filter
        const postTypes = ['post', 'page', 'news_talk', 'article', 'data-page'];
        // The default post type is 'post'. It is updated on the Filter page according to the currently selected post type.
        let postType = 'post';
        for (const type of postTypes) {
            if (queryStr.includes(type)) {
                postType = type;
                break;
            }
        }

        // check if query string already contains geography terms
        const count = (queryStr.match(/geography/g) || []).length;

        return (
            encodeURIComponent(
                `${postType}[refinementList][geography][${count}]`,
            ) +
            '=' +
            encodeURIComponent(value)
        );
    }

    /**
     * Generate Query String for Data Pages.
     * paramQuery.set() is used as Data Pages uses the encoding.
     * @param value
     * @param type {string}
     * @param value {string}
     * @return {string}
     * @private
     */
    _generateDataPageQueryString(type, value) {
        if (value) {
            let params = new URLSearchParams('');
            params.set(`data-${type}`, value);
            return params.toString();
        }
        return '';
    }

    /**
     * Generate Query String for Latest Posts block.
     * paramQuery.set() is used as Latest Posts JS uses the encoding.
     * @param value {string}
     * @return {string}
     * @private
     */
    _generateLatestPostsQueryString(value) {
        if (value) {
            let params = new URLSearchParams('');
            params.set(`geography`, value);
            return params.toString();
        }
        return '';
    }

    /**
     * @param returnValue {string}
     * @return {string|object}
     * @private
     */
    _getFirstChoiceValue(returnValue = 'value') {
        const value = this.firstChoice.getValue();
        switch (returnValue) {
            case 'value':
                return value.value;
            case 'label':
                return value.label;
            default:
                return value;
        }
    }

    /**
     * @param returnValue {string}
     * @return {string|object}
     * @private
     */
    _getSecondChoiceValue(returnValue = 'value') {
        const value = this.secondChoice.getValue();
        switch (returnValue) {
            case 'value':
                return value.value;
            case 'label':
                return value.label;
            default:
                return value;
        }
    }

    /**
     * Return current Second Select Option.
     * The object contains : option_text ; option_url ; show_geography_dropdown ; query_parameters ; data_page_type
     * @return {object|{}}
     * @private
     */
    _getSecondChoiceOption() {
        const key = this._getFirstChoiceValue();
        const secondKey = this._getSecondChoiceValue('label');

        if (this.secondOptions[key] === undefined || secondKey === undefined) {
            return {};
        }
        const option = this.secondOptions[key].filter(function (item) {
            if (secondKey === item['option_text']) {
                return item;
            }
        });
        return option[0] ?? {};
    }

    /**
     * @return {string}
     * @private
     */
    _getGeoChoiceValue() {
        return this.geoChoice.getValue(true);
    }

    _getGeoChoiceValueLabel() {
        const value = this.geoChoice.getValue();
        return value.label.trim();
    }
}

export default function () {
    const help1 = document.querySelectorAll('[data-module="help1"]');

    if (help1.length > 0) {
        help1.forEach((el) => {
            new Help1(el);
        });
    }
}
