export default () => {
    /**
     * Gets the anchor from the current location
     *
     **/
    const anchor = location.hash;

    /**
     * Open tab if url-hash exists
     **/
    (function () {
        if (anchor) {
            const anchorElement = document.querySelector(`a[href="${anchor}"]`);
            setTimeout(function () {
                anchorElement.click();
            }, 700);
        }
    })();

    // Settings for Tabs module
    const settings = {
        titleActiveClasses: 'tab-title--active',
        textActiveClasses: 'tab-gallery--active',
    };

    // CSS classes of Tab items.
    const selector = {
        module: '[data-module="dataGalleryA1"]',
        title: '.js-data-gallery-title',
        text: '.js-data-gallery-content',
    };

    /**
     * Actions to be done for tab module to start working.
     * @param module
     */
    const initTabModule = function (module) {
        // Attach the event listener to the parent element of the tab titles

        module
            .querySelectorAll(selector.title)
            .forEach(function (title, index) {
                title.addEventListener('click', titleClickEvent);

                if (!anchor && index === 0) {
                    title.click();
                }
            });
    };

    /**
     * Click Event to process tab title click.
     * @param {Event} event
     * @return {boolean}
     */
    function titleClickEvent(event) {
        const target = event.currentTarget;

        const index = target.dataset.tab_title;
        if (!index) {
            return false;
        }

        const container = getTabContainer(target);
        if (!container) {
            return false;
        }
        //
        hideTabs(container, index);
        showTab(container, index);
    }

    /**
     * Actions to be done for hiding tabs.
     * @param {HTMLElement} container
     * @param {int} activeIndex
     */
    function hideTabs(container, activeIndex) {
        const activeTabIndex = Number(activeIndex) - 1;
        // Remove active class from all tab title elements except active.
        container
            .querySelectorAll(selector.title)
            .forEach(function (title, index) {
                if (activeTabIndex !== index) {
                    removeClasses(title, settings.titleActiveClasses);
                }
            });

        // Remove active class from all tab text elements except active.
        container
            .querySelectorAll(selector.text)
            .forEach(function (text, index) {
                if (activeTabIndex !== index) {
                    removeClasses(text, settings.textActiveClasses);
                    $(text).hide(); // jQuery is used here, hence the eslint-disable-next-line comment.
                }
            });
    }

    /**
     * Actions to be done for showing active tab.
     * @param {HTMLElement} container
     * @param {int} index
     */
    function showTab(container, index) {
        if (index === '0') {
            // Show all tabs
            container
                .querySelectorAll(selector.title)
                .forEach(function (title) {
                    addClasses(title, settings.titleActiveClasses);
                });

            container.querySelectorAll(selector.text).forEach(function (text) {
                addClasses(text, settings.textActiveClasses);
                $(text).show(); // jQuery is used here
            });
        } else {
            // Show the specific tab
            const title = getTitleByIndex(container, index);
            const text = getTextByIndex(container, index);

            if (title) {
                addClasses(title, settings.titleActiveClasses);
            }

            if (text) {
                addClasses(text, settings.textActiveClasses);
                $(text).show(); // jQuery is used here
            }
        }
    }

    /**
     * Get tab module element from its any child.
     * @param {HTMLElement} childElement
     * @return {HTMLElement|undefined}
     */
    function getTabContainer(childElement) {
        return childElement.closest(selector.module);
    }

    /**
     Get tab title element by its index.
     * @param {HTMLElement} container
     * @param {int} index
     * @return {HTMLElement|undefined}
     */
    function getTitleByIndex(container, index) {
        const tabIndex = Number(index);
        return container.querySelector(`[data-tab_title="${tabIndex}"]`);
    }

    /**
     * Get tab text element by its index.
     * @param {HTMLElement} container
     * @param {int} index
     * @return {HTMLElement|undefined}
     */
    function getTextByIndex(container, index) {
        const tabIndex = Number(index);
        return container.querySelector(`[data-tab_text="${tabIndex}"]`);
    }

    /**
     * Adds several HTML classes.
     *
     * @param {HTMLElement} element
     * @param {string} classesString
     * @return boolean
     */
    function addClasses(element, classesString) {
        // check parameters to meet
        if (!(element instanceof HTMLElement) || !classesString) {
            return false;
        }

        const classes = classesString.split(' ');
        classes.forEach((className) => {
            element.classList.add(className);
        });
        return true;
    }

    /**
     * Removes several HTML classes.
     *
     * @param {HTMLElement} element
     * @param {string} classesString
     * @return boolean
     */
    function removeClasses(element, classesString) {
        // check parameters to meet
        if (!(element instanceof HTMLElement) || !classesString) {
            return false;
        }

        const classes = classesString.split(' ');
        classes.forEach((className) => {
            element.classList.remove(className);
        });
        return true;
    }

    // Initialize Tab module.
    document.querySelectorAll(selector.module).forEach(function (tab) {
        initTabModule(tab);
    });
};
