import Cookies from 'js-cookie';

/**
 * Replace hash in links based on a specified taxonomy.
 * @param {string} taxonomy - The taxonomy to search for in the links.
 */
function replaceHashLinks(taxonomy = 'geography') {
    //Get Cookie for Taxonomy Geography term slug
    const geographyTerm = Cookies.get('selected_trerc_geography');

    if (geographyTerm) {
        // Get all anchor elements on the page
        const links = document.querySelectorAll('a');

        // Loop through each anchor element
        links.forEach((link) => {
            // Get the href attribute of the anchor
            const href = link.getAttribute('href');

            // Check if the href contains "/#geography"
            if (href && href.includes(`/#${taxonomy}`)) {
                // Replace `/#${taxonomy}` with `/?${taxonomy}=<geographyTerm>`
                const newGeographyTerm = geographyTerm
                    .toLowerCase()
                    .replace(/\s+/g, '-');
                const newHref = href.replace(
                    `/#${taxonomy}`,
                    `/?${taxonomy}=${newGeographyTerm}`,
                );

                // Set the modified href back to the anchor
                link.setAttribute('href', newHref);
            }
        });
    }
}

// Call the function
export default replaceHashLinks;
