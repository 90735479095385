import Helpers from '/src/global/js/helpers/helpers.js';

function dropdownInit() {
    const dropdowns = document.querySelectorAll('.js-dropdown');

    // Stop if dropdowns didn't found
    if (dropdowns.length === 0) {
        return null;
    }

    dropdowns.forEach(function (dropdown) {
        // Get dropdown params
        let params = Helpers.getJsonFromAttr(dropdown.dataset.dropdown);

        // Set toggler
        let toggler = dropdown.querySelector('.js-dropdown-toggler');

        // Set bar
        let bar = dropdown.querySelector('.js-dropdown-bar');

        // Stop if Toggler or Bar didn't found
        if (toggler == null || bar == null) {
            return null;
        }
        // Get action
        let action = params.action ? params.action : 'hover';

        // Set active class
        let activeClass = 'js-dropdown-active';

        // Set 'position' modificator
        let position = params.position ? params.position : 'bottom-left';
        let positionClass = 'js-dropdown-' + position;
        dropdown.classList.add(positionClass);

        // When action is 'click'
        if (action == 'click') {
            toggler.addEventListener('click', (e) => {
                e.preventDefault();
                const isActive = dropdown.classList.contains(activeClass);
                Helpers.toggleClass(dropdown, activeClass);

                // Update aria-expanded attribute based on activeClass
                toggler.setAttribute(
                    'aria-expanded',
                    isActive ? 'false' : 'true',
                );
            });

            document.addEventListener('click', function (event) {
                if (
                    event.target != toggler &&
                    !toggler.contains(event.target) &&
                    event.target != bar &&
                    !bar.contains(event.target)
                ) {
                    dropdown.classList.remove(activeClass);
                    // Update aria-expanded attribute when activeClass is removed
                    toggler.setAttribute('aria-expanded', 'false');
                }
            });
        }

        // When action is 'hover'
        else if (action == 'hover') {
            var dropdownHideTimeout;

            var togglerHovered = false;
            var barHovered = false;

            // Show dropdown bar when toggler is hovered
            toggler.addEventListener('mouseover', (e) => {
                var togglerHovered = true;
                Helpers.removeClassVanillaJs(dropdowns, activeClass, dropdown);
                clearTimeout(dropdownHideTimeout);
                dropdown.classList.add(activeClass);
            });

            toggler.addEventListener('mouseout', (e) => {
                var togglerHovered = false;
                if (!barHovered) {
                    dropdownHideTimeout = setTimeout(
                        dropdownHide.bind(null, dropdown, activeClass),
                        600,
                    );
                }
            });

            // Accessibility support
            toggler.addEventListener('focusin', (e) => {
                var togglerHovered = true;
                clearTimeout(dropdownHideTimeout);
                dropdown.classList.add(activeClass);
            });

            toggler.addEventListener('focusout', (e) => {
                var togglerHovered = false;
                if (!barHovered) {
                    dropdownHide();
                }
            });

            // Don't hide dropdown bar when it's hovered
            bar.addEventListener('mouseover', (e) => {
                var barHovered = true;
                clearTimeout(dropdownHideTimeout);
                dropdown.classList.add(activeClass);
            });

            bar.addEventListener('mouseout', (e) => {
                var barHovered = false;
                if (!togglerHovered) {
                    dropdownHideTimeout = setTimeout(
                        dropdownHide.bind(null, dropdown, activeClass),
                        600,
                    );
                }
            });

            // Accessibility support
            bar.addEventListener('focusin', (e) => {
                var barHovered = true;
                clearTimeout(dropdownHideTimeout);
                dropdown.classList.add(activeClass);
            });

            bar.addEventListener('focusout', (e) => {
                var barHovered = false;
                if (!togglerHovered) {
                    dropdownHide(dropdown, activeClass);
                }
            });
        }
    });
}

function dropdownHide(dropdown, activeClass) {
    dropdown.classList.remove(activeClass);
}

export default dropdownInit;
