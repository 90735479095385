import AOS from 'aos';

function accordionMobileInit() {
    // Base hooks for the accordion
    const hooks = {
        group: '[data-accordion-mobile]',
        content: '[data-accordion-mobile-content]',
        title: '[data-accordion-mobile-title]',
        track: '[data-accordion-mobile-track]',
    };

    // All used classes are declared here
    const classes = {
        active: 'is-active',
        trackClicked: 'rotate-180',
    };

    const maxMobileScreenWidth = 1024;

    // Function to check if it's mobile view
    function isMobileView() {
        return window.innerWidth < maxMobileScreenWidth;
    }

    // Initialize accordions based on the viewport
    function initAccordions() {
        // Slide up or down based on the viewport width
        if (isMobileView()) {
            $(hooks.content).slideUp();
            $(hooks.track).addClass(classes.trackClicked);
        } else {
            $(hooks.content).slideDown();
            $(hooks.track).removeClass(classes.trackClicked);
        }

        // Set tabindex="0" for each title for accessibility
        $(hooks.title).each(function () {
            $(this).attr('tabindex', '0');
        });
    }

    // Toggle the accordion's state
    function toggleAccordion(e) {
        if (isMobileView()) {
            const group = $(this).closest(hooks.group);
            const content = group.find(hooks.content);
            const track = group.find(hooks.track);

            group.toggleClass(classes.active);
            track.toggleClass(classes.trackClicked);

            // eslint-disable-next-line jquery/no-slide
            content.slideToggle(300);

            // Refresh Animation on Scroll (AOS) events
            setTimeout(function () {
                AOS.refresh();
            }, 300);
        }
    }

    // Event binding for accordion interaction
    function bindAccordionEvents() {
        $(hooks.title)
            .off('click')
            .click(toggleAccordion)
            .off('keydown')
            .keydown(function (e) {
                if (e.keyCode === 13) {
                    // Enter key
                    toggleAccordion.call(this, e);
                }
            });
    }

    // Resize event to adjust accordion behavior based on viewport size
    $(window).resize(function () {
        initAccordions(); // Reinitialize accordions based on the new viewport size
    });

    // Initial setup
    bindAccordionEvents();
    initAccordions();
}

export default accordionMobileInit;
