import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

import Helpers from '/src/global/js/helpers/helpers.js';

Swiper.use([Navigation, Autoplay, Pagination, EffectFade]);
function swiperInit() {
    const allSwiperBlocks = document.querySelectorAll('[data-swiper]');

    // exit function if there isn't any slider
    if (allSwiperBlocks.length === 0) {
        return null;
    }

    allSwiperBlocks.forEach(function (block) {
        const buttonNext = block.querySelector('.js-swiper-btn-next');
        const buttonPrev = block.querySelector('.js-swiper-btn-prev');
        const pagination = block.querySelector('.js-swiper-pagination');

        const params = Helpers.getJsonFromAttr(block.dataset.swiper);

        const swiperParams = {
            loop: false,
            speed: 600,
            autoplay: false,
            slidesPerView: 1,
            spaceBetween: 32,
            navigation: {
                prevEl: buttonPrev,
                nextEl: buttonNext,
            },
            pagination: {
                el: pagination,
                type: 'bullets',
                clickable: true,
                renderBullet: function (index, className) {
                    return (
                        '<button class="' +
                        className +
                        '" aria-label="Slide ' +
                        index +
                        ' bullet"></button>'
                    );
                },
            },
            slideChange: function (swiper) {},

            // override default settings
            ...params,
        };

        function updateButtonFocusability(swiper) {
            // First, set tabindex to -1 for all buttons in all slides
            swiper.slides.forEach((slideEl) => {
                const buttons = slideEl.querySelectorAll('.a-btn');
                buttons.forEach((button) => {
                    button.setAttribute('tabindex', '-1');
                });
            });

            // Then, find the active slide
            const activeSlide = swiper.slides[swiper.activeIndex];

            // Set tabindex to 0 for buttons in the active slide
            if (activeSlide) {
                const activeButtons = activeSlide.querySelectorAll('.a-btn');
                activeButtons.forEach((button) => {
                    button.setAttribute('tabindex', '0');
                });
            }
        }

        const swiper = new Swiper(block, swiperParams);

        // Update button focusability on slide change
        swiper.on('slideChange', function () {
            updateButtonFocusability(this);
        });

        // Initial update of button focusability
        updateButtonFocusability(swiper);

        swiper.on('slideChange', function (e) {
            var imageLoading = swiper.el.querySelector(
                '[data-swiper-slide-index="' +
                    swiper.realIndex +
                    '"] img.lazyloading',
            );

            if (imageLoading) {
                imageLoading.classList.remove('lazyloading');
                imageLoading.classList.add('lazyloaded');
            }
        });
    });
}

export default swiperInit;
