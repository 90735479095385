/**
 * Handles the behavior of an accordion component.
 *
 * This function sets up event handlers for the accordion groups, links,
 * and audio elements within the accordion. It also manages the accordion's
 * state based on the URL hash.
 *
 * @author Dreamers of Day
 */
function showAccordionC() {
    // Define the CSS classes used in the accordion component
    const classes = {
        block: 'm-accordion-c',
        group: 'm-accordion-c__group',
        link: 'm-accordion-c__heading-link',
        text: 'm-accordion-c__text-wrapper',
        title: 'm-accordion-c__title',
        verticalControl: 'm-accordion-c__control--vertical',
        audioWrapper: 'm-accordion-c__audio-wrapper',
        active: 'is-active',
    };

    // Event handler for accordion link clicks
    $(`.${classes.link}`).click(function (e) {
        // Event handler for accordion links
        handleAccordionLinkClick(e);
        const group = $(e.target).closest(`.${classes.group}`);
        toggleAccordionGroup(group);
    });

    // Prevent closing while clicking on the audio player
    $(`.${classes.group} audio`).click(function (e) {
        e.stopPropagation();
    });

    // Add accordion for audio player
    $('[data-show-audio]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        const $audioWrapper = $(this)
            .closest(`.${classes.text}`)
            .find(`.${classes.audioWrapper}`);

        if ($audioWrapper.length) {
            $audioWrapper.slideToggle(300);
            const $audioPlayer = $audioWrapper.find('audio');

            // Play audio when opened, stop if closed
            if (!$(this).attr('data-audio-show-player')) {
                $audioPlayer.trigger('play');
                $(this).attr('data-audio-show-player', 'true');
            } else {
                $audioPlayer.trigger('pause');
                $(this).removeAttr('data-audio-show-player');
            }
        }
    });

    // Initialize accordion state based on URL hash
    initializeAccordionState();
}

/**
 * Toggles the state of an accordion group.
 *
 * @param {jQuery} group - The jQuery object representing the accordion group.
 */
function toggleAccordionGroup(group) {
    const classes = {
        text: 'm-accordion-c__text-wrapper',
        verticalControl: 'm-accordion-c__control--vertical',
        title: 'm-accordion-c__title',
        track: 'm-accordion-c__track',
        active: 'is-active',
    };

    // Toggle the state of elements within the group
    group.toggleClass(classes.active);
    group.find(`.${classes.verticalControl}`).toggleClass('rotate-90');
    group.find(`.${classes.title}`).toggleClass('text-brand-color-2-d');
    group.find(`.${classes.track}`).toggleClass('bg-brand-color-2-d');
    group.find(`.${classes.text}`).slideToggle(300);

    if (group.find(`.${classes.audioWrapper}`).is(':visible')) {
        // Pause audio only if accordion is collapsing
        if (!group.hasClass(classes.active)) {
            group.find('audio')[0]?.pause();
        }
    }
}

/**
 * Handles click events on accordion links.
 *
 * @param {Event} e - The event object.
 */
function handleAccordionLinkClick(e) {
    e.preventDefault();
    const classes = {
        group: 'm-accordion-c__group',
        active: 'is-active',
    };
    const group = $(e.target).closest(`.${classes.group}`);

    // Determine the new URL based on the accordion's state
    let newURL = '';
    if (group.hasClass(classes.active)) {
        const pageHashTarget = window.location.hash;
        newURL = window.location.href.replace(`${pageHashTarget}`, '');
    } else {
        newURL = e.currentTarget.href;
    }

    // Update the browser history
    window.history.pushState({ path: newURL }, '', newURL);
}

/**
 * Initializes the state of the accordion based on the current URL hash.
 */
function initializeAccordionState() {
    const anchor = location.hash ? document.querySelector(location.hash) : '';
    if (!anchor) {
        return;
    }
    if (!$(anchor).closest('[data-module="showAccordionC"]').length) {
        return;
    }

    const classes = {
        group: 'm-accordion-c__group',
        link: 'm-accordion-c__heading-link',
    };

    const group = $(anchor).closest(`.${classes.group}`);

    // Find and activate the relevant accordion tab
    const accordionTab = group
        .parents('[data-glossary-tab]')
        .first()
        .attr('data-glossary-tab');
    if (accordionTab) {
        const $glossarySelect = $('[data-glossary-select]');
        $glossarySelect[0]?.choices.setChoiceByValue(accordionTab);
        $glossarySelect?.trigger('change');
    }

    // Trigger a click on the corresponding link and scroll into view
    const link = group.find(`.${classes.link}`);
    if (link.length) {
        setTimeout(() => {
            link.click();
            anchor.scrollIntoView({ behavior: 'smooth' });
        }, 700);
    }
}

export default showAccordionC;
