/**
 * Enhances MailChimp forms by moving label text to placeholders and removing labels.
 * @param {Element} form - The form element to enhance.
 */

function mailChimpEmbedForm() {
    const forms = document.querySelectorAll("[id*='mc_embed'] form");
    forms.forEach(function (form) {
        // Function to move label text to placeholder and remove labels
        function moveLabelToPlaceholder() {
            const mcFieldGroups = form.querySelectorAll(
                '.mc-field-group:not(.input-group)',
            );
            mcFieldGroups.forEach(function (mcFieldGroup) {
                const labelElement = mcFieldGroup.querySelector('label');
                const inputElement = mcFieldGroup.querySelector('input');

                if (labelElement && inputElement) {
                    inputElement.placeholder = labelElement.textContent;
                    labelElement.remove(); // Remove the label after updating the placeholder
                }
            });
        }

        // Initial update of placeholders
        moveLabelToPlaceholder();
    });
}

document.addEventListener('DOMContentLoaded', function () {
    mailChimpEmbedForm(); // Call the formOrEmbed function here
});

export default mailChimpEmbedForm;
