import './global/js/util/modernizr';
import './global/js/util/polyfills';
import './style.scss';
import './global/js/core/core';

/*
 * Accordions
 */
import accordionMobileInit from './global/js/accordions/accordion-mobile-init';
/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
// import cursorEffects from './global/js/animations/cursor-effects';
import parallaxScroll from './global/js/animations/parallax-light';
// import progressBar from './global/js/animations/progress';
// import letterEffects from './global/js/animations/splittext';
/*
 * Forms
 */
import mailChimpEmbedForm from './global/js/form/mailchimp-embed-form/mailchimp-embed-form';
/*
 * Misc
 */
import replaceHashLinks from './global/js/helpers/replace-links-hash';
import dropdownInit from './global/js/misc/dropdown/dropdown-init';
import externalLinks from './global/js/misc/external-links/external-links';
import customMicromodalCall from './global/js/misc/micromodal/micromodal';
import swiperInit from './global/js/misc/swiper/swiper-init';
import tableInit from './global/js/misc/table/table-init';
import tippyTooltips from './global/js/misc/tippy/tippy';
/*
 * Atoms
 */
// import floatingCta from './patterns/01-atoms/buttons/button-floating-cta-a/button-floating-cta-a-1/button-floating-cta-a-1';
import scrollToTop from './patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
/*
 * Molecules
 */
import showAccordion from './patterns/02-molecules/accordions/accordion-a/accordion-a-1';
import showAccordionB from './patterns/02-molecules/accordions/accordion-b/accordion-b-1';
import showAccordionC from './patterns/02-molecules/accordions/accordion-c/accordion-c-1';
// import debugControls from './patterns/02-molecules/admin/debug-controls-a/debug-controls-a-1';
import feedbackA from './patterns/02-molecules/article-elements/feedback-a/feedback-a-1/feedback-a-1';
import multiselectDropdown from './patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1';
import galleryA from './patterns/02-molecules/galleries/gallery-a/gallery-a-1';
import help1 from './patterns/02-molecules/help/help-1/help-1';
import mapBlock from './patterns/02-molecules/maps/map-a/map-a-1';
import modal from './patterns/02-molecules/modals/modal-a/modal-a-1';
import modalVideoA1 from './patterns/02-molecules/modals/modal-video-a/modal-video-a-1';
import { hamburger } from './patterns/02-molecules/nav/hamburger-a/hamburger-a-1';
// import navigateCategory from './patterns/02-molecules/nav/nav-category-a/nav-category-a-1';
import navMob from './patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
import searchBar from './patterns/02-molecules/search/search-bar-a/search-bar-a-1';
import { slideOut } from './patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1';
// import sliderImages from './patterns/02-molecules/sliders/slider-images-a/slider-images-a-1';
// import sliderTestimonials from './patterns/02-molecules/sliders/slider-testimonials-a/slider-testimonials-a-1';
import socialShare from './patterns/02-molecules/social/social-share-a/social-share-a-1';
// import table1 from './patterns/02-molecules/table/table-1/table-1';
import tabs from './patterns/02-molecules/tabs/tabs-a/tabs-a-1';
import widgetTranslation from './patterns/02-molecules/widgets/widget-translation/widget-translation';
import dataGalleryA1 from './patterns/03-organisms/repeatable-custom/data-gallery-a/data-gallery-a-1/data-gallery-a-1';
import filterType1 from './patterns/03-organisms/repeatable-custom/filter-a/filter-a-1/filter-a-1';
/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP
import glossaryA1 from './patterns/03-organisms/repeatable-custom/glossary-a/glossary-a-1/glossary-a-1';
import latestPostsA1 from './patterns/03-organisms/repeatable-custom/latest-posts-a/latest-posts-a-1/latest-posts-a-1';
import latestPostsA6 from './patterns/03-organisms/repeatable-custom/latest-posts-a/latest-posts-a-6/latest-posts-a-6';
// import visualization1 from './patterns/03-organisms/repeatable-custom/visualization/visualization-1/visualization-1';
import sidebarPostsA1 from './patterns/03-organisms/repeatable-sidebar/posts-a/posts-a-1/posts-a-1';
/*
 * Organisms
 */
import headerInit from './patterns/03-organisms/single/header/header-init';
import hero2 from './patterns/03-organisms/single/hero/hero-2/hero-2';
import postsOverview1 from './patterns/03-organisms/single/posts-overview/posts-overview-1/posts-overview-1';
/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    showAccordion,
    showAccordionB,
    showAccordionC,
    // sliderImages,
    // sliderTestimonials,
    // navigateCategory,
    searchBar,
    scrollToTop,
    feedbackA,
    // floatingCta,
    socialShare,
    hamburger,
    slideOut,
    modal,
    modalVideoA1,
    // debugControls,
    tabs,
    mapBlock,
    galleryA,
    filterType1,
    multiselectDropdown,
    // progressBar,
    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
    glossaryA1,
    dataGalleryA1,
    postsOverview1,
    help1,
    hero2,
    // visualization1,
    // table1,
    latestPostsA1,
    latestPostsA6,
    sidebarPostsA1,
    mailChimpEmbedForm,
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw `Module '${module}' not found`;
    }
};

jQuery(document).ready(() => {
    widgetTranslation($);
    navMob($);
    externalLinks($);
    customMicromodalCall($);
    tippyTooltips();
    headerInit();
    dropdownInit();
    swiperInit();
    tableInit();
    accordionMobileInit();
    replaceHashLinks();

    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function () {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    modal($);

    /*
     * GSAP based animations. Hide if not used.
     */
    // cursorEffects($);
    // letterEffects($);
    // scrollSkewer($);
});

jQuery(window).scroll(() => {
    parallaxScroll($);
});
