import Cookies from 'js-cookie';
import { DateRangePicker } from 'vanillajs-datepicker';

/**
 * reach the object in the global scope
 * window.swtInstance.cm_swt_instant_search_1
 *
 * useful methods:
 * swtInstance.once('render', initializedActions);
 * swtInstance.refresh();
 * swtInstance.helper.on('change', helperChangeEvent);
 * swtInstance.removeListener('render', initializedActions);
 * swtInstance.mainIndex.dispose();
 * swtInstance.helper.getRefinements('topic');
 * swtInstance.helper.getQuery();
 */
export default () => {
    function moduleInit(module) {
        let swtInstance = false;
        let moduleId = '';
        let queryArgsToSave = '';
        //
        let cookieTerms;
        let defaultQueryTerms;
        let mutableTerms;
        let immutableTerms;
        //
        let updateDefaultTerms = 0;
        let updateMutableTerms = 0;
        let updateImmutableTerms = 0;
        let updatingTerms = 0;
        let isDebug = 0;
        //
        const bodyClasses = [
            'instant-search__filter-mobile--open',
            'overflow-hidden',
            'relative',
            'h-full',
        ];

        function moduleInitialized() {
            if (swtInstance.status === 'idle') {
                module.dataset.initialized = '1';
            }
        }

        function initializedActions() {
            const searchQuery = window.location.search;
            const currentCollectionName = getCurrentCollectionName();
            const collections = module.querySelectorAll(
                '.cmswt-IndexSwitcher-item [data-collection]',
            );
            collections.forEach(function (item) {
                const collectionName = item.getAttribute('data-collection');
                if (
                    -1 !== searchQuery.indexOf(collectionName) &&
                    currentCollectionName !== collectionName
                ) {
                    if (isDebug) {
                        console.log(
                            'change Index according to URL [ trigger click ]',
                        );
                    }
                    module.dataset.initialized = '0';
                    item.click();
                }
            });
        }

        /**
         * Initializes the custom date range picker and sets up event listeners for date changes.
         */
        function initializeCustomDateRange() {
            const elem = document.getElementById(
                'ais-RefinementList--date-range-wrapper',
            );
            if (!elem || !DateRangePicker) {
                return;
            }
            new DateRangePicker(elem, {
                format: 'mm-dd-yyyy',
                allowOneSidedRange: true,
                todayHighlight: true,
                clearButton: true,
                maxDate: new Date(),
            });
            const customRangeInputs = module.querySelectorAll(
                '.cmswt-date_custom_range__input',
            );

            if (customRangeInputs) {
                customRangeInputs.forEach((input) => {
                    input.addEventListener(
                        'changeDate',
                        customDateRangeChangeEventHandler,
                    );
                });
            }
        }

        /**
         * Set taxonomy terms form the Cookies
         */
        const getCookieTerms = function () {
            let cookieTerms = {};
            // taxonomy Persona
            const personaCookie = Cookies.get('selected_trerc_persona');
            if (personaCookie) {
                cookieTerms['persona'] = [personaCookie];
            }
            return cookieTerms;
        };

        /**
         * Set preselected terms which are able to close
         * They are rendered only once on Typesense render for the first time
         * @example swtInstance.renderState.post.refinementList.category.refine('Housing');
         * @return {boolean}
         */
        function setMutableTerms() {
            if (Object.keys(mutableTerms).length === 0 || updateMutableTerms) {
                return false;
            }
            addTerms(mutableTerms);
            updateMutableTerms = 1;
            if (isDebug) {
                console.log('add mutableTerms');
            }
        }

        function setDefaultTerms() {
            if (
                Object.keys(defaultQueryTerms).length === 0 ||
                updateDefaultTerms
            ) {
                return false;
            }
            addTerms(defaultQueryTerms);
            updateDefaultTerms = 1;
            if (isDebug) {
                console.log('add defaultQueryTerms');
            }
        }

        function setImmutableTerms() {
            if (
                Object.keys(immutableTerms).length === 0 ||
                updateImmutableTerms
            ) {
                return false;
            }
            addTerms(immutableTerms);
            updateImmutableTerms = 1;
            if (isDebug) {
                console.log('add immutableTerms');
            }
        }

        /**
         * addDisjunctiveFacetRefinement('filter_name', ['term_1', 'term_2']
         * addDisjunctiveRefine({
             attributeName: 'filter_name',
             values: ['term_1', 'term_2'],
             operator: 'OR',
         * }) // operator is AND
         * @param termsArray
         */
        function addTerms(termsArray) {
            for (const taxSlug in termsArray) {
                const terms = termsArray[taxSlug];
                const facetsRefinements =
                    swtInstance.helper.state.disjunctiveFacetsRefinements[
                        taxSlug
                    ];

                for (let i = 0; terms.length > i; i++) {
                    const term = terms[i];
                    if (!facetsRefinements.includes(term)) {
                        swtInstance.helper.addDisjunctiveFacetRefinement(
                            taxSlug,
                            term,
                        );
                    }
                }
            }
        }

        /**
         * Hides HTML elements for immutable terms in the current refinements.
         *
         * @return {boolean} Returns false if there are no immutable terms or if terms are not being updated.
         */
        function hideImmutableTermsHTMLElements() {
            if (Object.keys(immutableTerms).length === 0 || !updatingTerms) {
                return false;
            }
            const refinements = module.querySelectorAll(
                '.ais-CurrentRefinements-categoryLabel',
            );

            refinements.forEach(function (item) {
                const text = item.innerHTML;

                for (let taxSlug in immutableTerms) {
                    const isImmutable = immutableTerms[taxSlug].find(
                        (el) => text === el,
                    );

                    if (!isImmutable) {
                        continue;
                    }
                    const parent = item.closest('.ais-CurrentRefinements-item');
                    parent.style.display = 'none';
                }
            });

            if (isDebug) {
                console.log('hide Immutable Terms HTMLElements');
            }
        }

        /**
         * Filter Panel items for mobile view.
         * Set items with selected refinements to be open,
         * and items with no selected refinements to be closed.
         */
        function mobileFilterItemsToggle() {
            const filterItems = module.querySelectorAll('.ais-Panel');
            filterItems.forEach(function (item) {
                const selectedTerms = item.querySelectorAll(
                    '.ais-RefinementList-item--selected',
                );
                const button = item.querySelector('.ais-Panel-collapseButton');

                if (
                    selectedTerms.length &&
                    !item.classList.contains('ais-Panel--collapsed')
                ) {
                    button.click();
                } else if (
                    !selectedTerms.length &&
                    item.classList.contains('ais-Panel--collapsed')
                ) {
                    button.click();
                }
            });
        }

        /**
         * Handles various click events within the module and triggers the appropriate actions.
         *
         * @param {Event} e - The click event object.
         */
        function moduleClickEventHandler(e) {
            const target = e.target;

            const filterPanel = target.closest('.cmswt-FilterPanel-toggle');
            if (filterPanel) {
                mobileFilterItemsToggle();
                toggleBodyClasses('open');
                return;
            }
            const button = target.closest('.ais-Panel-collapseButton');
            if (button) {
                const inverted = e.isTrusted;
                itemsToggleClick(button, inverted);
                return;
            }
            const closeBtn = target.closest('.cmswt-FilterPanel-itemsClose');
            if (closeBtn) {
                mobileFilterItemsToggle();
                toggleBodyClasses('close');
                return;
            }
            const paginationLink = target.closest('.ais-Pagination-link');
            if (paginationLink) {
                scrollToTop();
                return;
            }
            const indexSwitcherLink = target.closest(
                '.cmswt-IndexSwitcher-link',
            );
            if (indexSwitcherLink) {
                init_swtInstance();
                renderActions();
                dateOptionFilterAvailability();
                dateCustomRangeFilterAvailability();
                modifyArticleIssuesFiltersTextHTMLElements();
                return;
            }
            const dateOptionFilter = target.closest(
                '.cmswt-Filter-date_option--label',
            );
            if (dateOptionFilter) {
                dateOptionFilterEventHandler(dateOptionFilter);
                //
                const startDate = module.querySelector(
                    '[name="cmswt-date_custom_range--start"]',
                );
                const endDate = module.querySelector(
                    '[name="cmswt-date_custom_range--end"]',
                );
                if (startDate) {
                    startDate.value = '';
                }
                if (endDate) {
                    endDate.value = '';
                }
            }
            //
            const clearFilterButton = target.closest(
                '.ais-ClearRefinements-button',
            );
            const clearRefinementButton = target.closest(
                '.ais-CurrentRefinements-delete',
            );
            if (clearFilterButton || clearRefinementButton) {
                dateOptionFilterAvailability();
                dateCustomRangeFilterAvailability();
            }
            //
            const showMoreArticleIssues = target.closest(
                '.cmswt-Filter-article-issues .ais-RefinementList-showMore',
            );
            if (showMoreArticleIssues) {
                modifyArticleIssuesFiltersTextHTMLElements();
            }
        }

        /**
         * Handles the custom date range change event and updates relevant filters.
         *
         * @param {Event} e - The event triggered by the change in the custom date range input.
         */
        function customDateRangeChangeEventHandler(e) {
            const target = e.target;
            const dateCustomRangeFilter = target.closest(
                '.cmswt-Filter-date_custom_range',
            );

            if (!dateCustomRangeFilter) {
                return;
            }
            dateCustomRangeFilterEventHandler(dateCustomRangeFilter);
            //
            const inputs = module.querySelectorAll(
                '[name="cmswt-date_option"]',
            );
            if (inputs) {
                inputs.forEach((el) => {
                    el.checked = false;
                    const parent = el.closest('.ais-RefinementList-item');
                    parent.classList.remove(
                        'ais-RefinementList-item--selected',
                    );
                });
            }
        }

        /**
         * inverted=1 => isTrusted=true - means the user clicked
         * inverted=0 => isTrusted=false - means click is called from the code
         * @param button {HTMLElement}
         * @param inverted {boolean} Flag to show if active class has been set
         */
        function itemsToggleClick(button, inverted) {
            const parent = button.closest('.ais-Panel--collapsible');
            const items = parent.querySelector('.ais-Panel-body');

            // check if it is the Date Option filter which is custom.
            const dateOption = button.closest('.cmswt-Filter-date_option');
            const dateCustomRange = button.closest(
                '.cmswt-Filter-date_custom_range',
            );
            if (dateOption || dateCustomRange) {
                if (inverted) {
                    parent.classList.toggle('ais-Panel--collapsed');
                } else {
                    inverted = true;
                    parent.classList.remove('ais-Panel--collapsed');
                }
            }

            if (inverted) {
                if (parent.classList.contains('ais-Panel--collapsed')) {
                    jQuery(items).slideDown();
                } else {
                    jQuery(items).slideUp();
                }
            } else {
                if (parent.classList.contains('ais-Panel--collapsed')) {
                    jQuery(items).slideUp();
                } else {
                    jQuery(items).slideDown();
                }
            }
        }

        /**
         * Toggle animation for the Loader
         */
        function toggleLoader() {
            if (
                swtInstance.status === 'loading' ||
                swtInstance.status === 'stalled'
            ) {
                const height = module.clientHeight;
                module.style.height = `${height}px`;
                module.classList.add('h-loader');
            } else {
                module.style.height = '';
                module.classList.remove('h-loader');
            }
        }

        /**
         * Toggles body classes based on the specified action.
         *
         * @param {string} action - The action to perform ('open' or 'close').
         */
        function toggleBodyClasses(action) {
            if ('open' === action) {
                bodyClasses.forEach(function (item) {
                    document.body.classList.add(item);
                });
            } else if ('close' === action) {
                bodyClasses.forEach(function (item) {
                    document.body.classList.remove(item);
                });
            }
        }

        /**
         * Smoothly scrolls the page to the top of the module, accounting for header height.
         */
        function scrollToTop() {
            const headerHeight = jQuery(
                document.querySelector('.o-header-1__placeholder'),
            ).height();
            const scrollTop = jQuery(module).offset().top - headerHeight - 20;

            jQuery('html,body').animate(
                {
                    scrollTop: scrollTop,
                },
                'fast',
            );
        }

        /**
         * Initializes Choices.js for the sort dropdown, if not already initialized.
         */
        function choicesJSInit() {
            if (module.querySelector('.cmswt-SortBy .choices')) {
                return;
            }
            const element = document.querySelector('.ais-SortBy-select');
            if (element) {
                new Choices(element, {
                    searchEnabled: false,
                    searchChoices: false,
                    allowHTML: true,
                });
            }
        }

        /**
         * Handles resizing event and toggles body classes based on the filter panel's state.
         */
        function resizeEventHandler() {
            const filterPanel = module.querySelector(
                '.cmswt-FilterPanel-items',
            );
            if (
                filterPanel.classList.contains('.cmswt-FilterPanel-items--show')
            ) {
                toggleBodyClasses('open');
            } else {
                toggleBodyClasses('close');
            }
        }

        /**
         * Extracts and returns default query terms from the current URL search parameters.
         *
         * @return {Object} An object representing the query terms.
         */
        function getDefaultQueryTerms() {
            let searchQuery = window.location.search;
            //
            if (!searchQuery) {
                return [];
            }
            searchQuery = decodeURIComponent(searchQuery);
            // remove "?"
            searchQuery = searchQuery.substring(1);
            //
            let queryArray = searchQuery.split('&');
            //
            const regex = /\[([^\]]+)\]/g;
            let queryTerms = {};
            for (const item of queryArray) {
                if (-1 === item.indexOf('refinementList')) {
                    return [];
                }
                let queryUnit = item.split('=');

                const key = queryUnit[0];
                const matches = key.match(regex);
                const extractedTexts = matches.map((match) =>
                    match.slice(1, -1),
                ); // Remove the brackets

                if ('refinementList' !== extractedTexts[0]) {
                    continue;
                }

                if (queryTerms[extractedTexts[1]]) {
                    queryTerms[extractedTexts[1]].push(queryUnit[1]);
                } else {
                    queryTerms[extractedTexts[1]] = [queryUnit[1]];
                }
            }
            return queryTerms;
        }

        function setQueryState() {
            if (
                swtInstance.status === 'loading' ||
                swtInstance.status === 'stalled'
            ) {
                return;
            }
            setTimeout(setPostTypeQuery, 500);
        }

        /**
         * Sets the post-type query based on the current collection name and updates the query string.
         */
        function setPostTypeQuery() {
            const url = window.location;
            let searchQuery = url.search;

            const collectionName = getCurrentCollectionName();

            if (-1 !== searchQuery.indexOf(collectionName)) {
                setSaveQueryParams();
                return;
            }

            const currentQuery = swtInstance.helper.getQuery();
            if (currentQuery['facetFilters']) {
                // update query strings
                swtInstance.helper.setQuery();
                if (isDebug) {
                    console.log('Update Query string');
                }
            } else {
                const indexes = module.querySelectorAll(
                    '.cmswt-IndexSwitcher-item',
                );
                if (indexes.length > 1) {
                    // set post-type name
                    history.pushState(
                        {},
                        '',
                        `${url.origin}${url.pathname}?${collectionName}`,
                    );
                    if (isDebug) {
                        console.log('Set post-type forcibly in Query string');
                    }
                }
            }
            setSaveQueryParams();
        }

        /**
         * Appends saved query parameters to the URL if not already present.
         */
        function setSaveQueryParams() {
            if (!queryArgsToSave) {
                return;
            }
            const newURL = window.location;
            // set post-type name
            setTimeout(function () {
                if (isDebug) {
                    console.log('add "db" query parameter');
                }
                if (-1 === newURL.href.indexOf(queryArgsToSave)) {
                    if (0 === newURL.search.indexOf('?')) {
                        history.pushState(
                            {},
                            '',
                            `${newURL.href}&${queryArgsToSave}`,
                        );
                    } else {
                        history.pushState(
                            {},
                            '',
                            `${newURL.href}?${queryArgsToSave}`,
                        );
                    }
                }
            }, 500);
        }

        /**
         * Retrieves the current active collection name.
         *
         * @return {string} The collection name from the active item.
         */
        function getCurrentCollectionName() {
            return module
                .querySelector(
                    '.cmswt-IndexSwitcher-item.active [data-collection]',
                )
                .getAttribute('data-collection');
        }

        /**
         * Handles rendering updates and manages the state of various components.
         */
        function renderEventHandler() {
            toggleLoader();

            hideImmutableTermsHTMLElements();
            replaceDateOptionTermsHTMLElements();
            replaceDateCustomRangeTermsHTMLElements();
            //
            modifyArticleIssuesTermsText();
            //
            if ('idle' === swtInstance.status) {
                choicesJSInit();
            }

            if ('idle' === swtInstance.status && updatingTerms) {
                updateDefaultTerms = 0;
                updateMutableTerms = 0;
                updateImmutableTerms = 0;
                updatingTerms = 0;
            } else {
                setImmutableTerms();
            }

            if (
                (updateDefaultTerms ||
                    updateMutableTerms ||
                    updateImmutableTerms) &&
                !updatingTerms
            ) {
                swtInstance.refresh();
                updatingTerms = 1;
                if (isDebug) {
                    console.log('update terms [ swtInstance.refresh() ]');
                }
            }

            if ('idle' === swtInstance.status) {
                setQueryState();
            }

            moduleInitialized();
        }

        function init_swtInstance() {
            moduleId = module.dataset.id;
            swtInstance = window.swtInstance[moduleId];
        }

        function renderActions() {
            if (typeof window.dataLayer === 'object') {
                swtInstance.on('render', dataLayerTracking);
            } else {
                console.log('"dataLayer" object is absent');
            }

            swtInstance.once('render', setMutableTerms);
            swtInstance.on('render', renderEventHandler);
            swtInstance.on(
                'render',
                modifyArticleIssuesFiltersTextHTMLElements,
            );
        }

        /**
         * Saves specific query parameters from the URL to a variable for future use.
         */
        function saveQueryParams() {
            const paramsToSave = [
                'db',
                'geography',
                'data-State',
                'data-MSA',
                'data-MD',
                'data-MiSA',
                'data-County',
            ];
            const urlParams = new URLSearchParams(window.location.search);
            const saveParams = new URLSearchParams();
            paramsToSave.forEach((item) => {
                let dynamicBlocksValue = urlParams.get(item);
                if (dynamicBlocksValue) {
                    saveParams.append(item, dynamicBlocksValue);
                }
            });
            queryArgsToSave = saveParams.toString();
        }

        /**
         * Handles the event for selecting a date option and updates the refinement filters.
         *
         * @param {HTMLElement} dateOptionFilter The date option filter container element.
         */
        function dateOptionFilterEventHandler(dateOptionFilter) {
            const input = dateOptionFilter.querySelector(
                '[name="cmswt-date_option"]:checked',
            );
            if (!input) {
                return;
            }
            const filterValue = input.value;
            if (!filterValue) {
                return;
            }

            const inputs = module.querySelectorAll(
                '[name="cmswt-date_option"]',
            );
            inputs.forEach((el) => {
                const parent = el.closest('.ais-RefinementList-item');
                parent.classList.remove('ais-RefinementList-item--selected');
            });

            swtInstance.helper.removeNumericRefinement('sort_by_date');
            swtInstance.helper
                .addNumericRefinement('sort_by_date', '>=', Number(filterValue))
                .search();

            const parent = input.closest('.ais-RefinementList-item');
            parent.classList.add('ais-RefinementList-item--selected');
        }

        /**
         * Replaces the HTML elements for selected date option refinements with updated labels.
         */
        function replaceDateOptionTermsHTMLElements() {
            const input = module.querySelector(
                '.cmswt-Filter-date_option [name="cmswt-date_option"]:checked',
            );
            if (!input || !input.value) {
                return false;
            }
            const refinements = module.querySelectorAll(
                '.ais-CurrentRefinements-categoryLabel',
            );

            refinements.forEach(function (item) {
                const text = item.innerHTML;

                if (text.indexOf(input.value) === -1) {
                    return;
                }
                const parent = item.closest('.ais-CurrentRefinements-item');

                const termText = 'Date:';
                const label = input.nextSibling.innerHTML;

                const facetLabelContainer = parent.querySelector(
                    '.ais-CurrentRefinements-label',
                );
                const facetValueContainer = parent.querySelector(
                    '.ais-CurrentRefinements-categoryLabel',
                );

                facetLabelContainer.innerHTML = termText;
                facetValueContainer.innerHTML = label;
            });

            if (isDebug) {
                console.log('replace DateOption Terms HTML Elements');
            }
        }

        /**
         * Clears selected date options if no start date refinement is applied.
         */
        function dateOptionFilterAvailability() {
            const numericRefinements =
                swtInstance.helper.state.getNumericRefinement(
                    'sort_by_date',
                    '>=',
                );
            if (numericRefinements && numericRefinements.length) {
                return;
            }

            const inputs = module.querySelectorAll(
                '[name="cmswt-date_option"]',
            );
            inputs.forEach((el) => {
                const parent = el.closest('.ais-RefinementList-item');
                parent.classList.remove('ais-RefinementList-item--selected');
            });
        }

        /**
         * Handles the event for custom date range filtering and updates the search helper refinements.
         *
         * @param {HTMLElement} dateCustomRangeFilter The date range filter container element.
         */
        function dateCustomRangeFilterEventHandler(dateCustomRangeFilter) {
            const startDate = dateCustomRangeFilter.querySelector(
                '[name="cmswt-date_custom_range--start"]',
            );
            const endDate = dateCustomRangeFilter.querySelector(
                '[name="cmswt-date_custom_range--end"]',
            );

            const startDateValue = startDate ? startDate.value : false;
            const endDateValue = endDate ? endDate.value : false;
            swtInstance.helper.removeNumericRefinement('sort_by_date');

            if (startDateValue) {
                swtInstance.helper.addNumericRefinement(
                    'sort_by_date',
                    '>=',
                    dateStringToTimestamp(startDateValue),
                );
            }
            if (endDateValue) {
                swtInstance.helper.addNumericRefinement(
                    'sort_by_date',
                    '<=',
                    dateStringToTimestamp(endDateValue),
                );
            }

            swtInstance.helper.search();
        }

        /**
         * Replaces the HTML elements displaying the date range refinements with human-readable date labels.
         */
        function replaceDateCustomRangeTermsHTMLElements() {
            const startDate = module.querySelector(
                '[name="cmswt-date_custom_range--start"]',
            );
            const endDate = module.querySelector(
                '[name="cmswt-date_custom_range--end"]',
            );

            const startDateValue = startDate ? startDate.value : false;
            const endDateValue = endDate ? endDate.value : false;
            if (!startDateValue && !endDateValue) {
                return;
            }

            const startDateTimestamp = startDateValue
                ? dateStringToTimestamp(startDateValue)
                : false;
            const endDateTimestamp = endDateValue
                ? dateStringToTimestamp(endDateValue)
                : false;

            const startDateStr = startDateValue
                ? dateStrToHumanReadable(startDateValue)
                : false;
            const endDateStr = endDateValue
                ? dateStrToHumanReadable(endDateValue)
                : false;

            //
            const refinements = module.querySelectorAll(
                '.ais-CurrentRefinements-categoryLabel',
            );

            let startRendered = false;
            let endRendered = false;

            refinements.forEach(function (item) {
                if (startRendered && endRendered) {
                    return;
                }
                const text = item.innerHTML;

                let termText = 'Date:';
                let label = '';
                if (text.indexOf(startDateTimestamp) !== -1 && !startRendered) {
                    label = `Start - ${startDateStr}`;
                    startRendered = true;
                } else if (
                    text.indexOf(endDateTimestamp) !== -1 &&
                    !endRendered
                ) {
                    label = `End - ${endDateStr}`;
                    endRendered = true;
                } else {
                    return;
                }

                const parent = item.closest('.ais-CurrentRefinements-item');

                const facetLabelContainer = parent.querySelector(
                    '.ais-CurrentRefinements-label',
                );

                facetLabelContainer.innerHTML = termText;
                item.innerHTML = label;
            });

            if (isDebug) {
                console.log('replace DateCustomRange Terms HTML Elements');
            }
        }

        /**
         * Clears the datepicker inputs if no date refinements are set.
         */
        function dateCustomRangeFilterAvailability() {
            const numericRefinementsStart =
                swtInstance.helper.state.getNumericRefinement(
                    'sort_by_date',
                    '>=',
                );
            const numericRefinementsEnd =
                swtInstance.helper.state.getNumericRefinement(
                    'sort_by_date',
                    '<=',
                );
            if (!numericRefinementsStart || !numericRefinementsStart.length) {
                const input = module.querySelector(
                    '[name="cmswt-date_custom_range--start"]',
                );
                if (input.datepicker) {
                    input.datepicker.setDate({ clear: true });
                }
            }
            if (!numericRefinementsEnd || !numericRefinementsEnd.length) {
                const input = module.querySelector(
                    '[name="cmswt-date_custom_range--end"]',
                );
                if (input.datepicker) {
                    input.datepicker.setDate({ clear: true });
                }
            }
        }

        /**
         * Converts a date string ("MM-DD-YYYY") to a timestamp in seconds.
         */
        function dateStringToTimestamp(dateStr) {
            // Convert to seconds by dividing by 1000
            return Math.floor(getUTCDate(dateStr).getTime() / 1000);
        }

        /**
         * Converts a date string ("MM-DD-YYYY") to a human-readable format in UTC.
         */
        function dateStrToHumanReadable(dateStr) {
            const date = getUTCDate(dateStr); // Assuming getUTCDate correctly returns a Date object in UTC

            // Convert the date to a human-readable format in UTC
            return date.toLocaleString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                timeZone: 'UTC', // Ensure it's rendered in UTC
            });
        }

        /**
         * Tracks and sends content library filter refinements to the dataLayer.
         * This function checks if the system is idle and if the module has been initialized.
         * It then processes the available refinements (e.g., topic, persona, etc.),
         * converts them to camelCase, and sends the results to the dataLayer for tracking.
         *
         * @private
         */
        function dataLayerTracking() {
            // Check if the current status is idle, if not, stop processing
            if (swtInstance.status !== 'idle') {
                return; // Exit if status is not idle
            }

            // Check if the module has been initialized; if not, stop processing
            if (!Number(module.dataset.initialized)) {
                return;
            }

            // List of refinement categories to track
            const refinements = [
                'topic',
                'persona',
                'geography',
                'article-types',
                'article-authors',
                'source',
            ];

            // Object to hold the event and refinements for dataLayer
            let refinementsValues = {
                event: 'content_library_filter', // Event name for tracking
            };

            // Flag to determine whether to send dataLayer event
            let sendFlag = false;

            // Loop through each refinement category
            refinements.forEach((ref) => {
                // Get the refinement values
                const refValues = swtInstance.helper.getRefinements(ref);

                if (refValues.length === 0) {
                    return;
                }

                // Set flag to true if there are values to send
                sendFlag = true;

                // Convert refinement key (e.g., article-types => articleTypes)
                const refKey = ref
                    .replace(/[-_]+/g, ' ')
                    .toLowerCase()
                    .split(' ')
                    .map((word, index) => {
                        return index === 0
                            ? word
                            : word.charAt(0).toUpperCase() + word.slice(1);
                    })
                    .join('');

                // Join the refinement values into a comma-separated string
                const refCommaSeparatedValues = refValues
                    .map((item) => item.value)
                    .join(', ');

                refinementsValues[refKey] = refCommaSeparatedValues;
            });

            // If no refinements were processed, don't send anything
            if (!sendFlag) {
                return;
            }
            console.log(refinementsValues);

            // Push the refinementsValues object to the dataLayer if it exists
            if (typeof window.dataLayer === 'object') {
                window.dataLayer.push(refinementsValues);
            }
        }

        /**
         * Converts a date string ("MM-DD-YYYY") to a UTC Date object.
         */
        function getUTCDate(dateStr) {
            // Original date string in "MM-DD-YYYY" format
            // Parse the date parts
            const [month, day, year] = dateStr.split('-').map(Number);

            // Validate if the date parts are correct
            if (!month || !day || !year) {
                throw new Error("Invalid date format. Expected 'MM-DD-YYYY'");
            }

            // Create a Date object in UTC (note: month is 0-indexed)
            return new Date(Date.UTC(year, month - 1, day));
        }

        /**
         * Replaces the text content of article issue filter HTML elements by extracting the last two words.
         *
         * This function selects all options within the 'article-issues' filter and modifies the label text
         * by extracting only the last two words for each element.
         */
        function modifyArticleIssuesFiltersTextHTMLElements() {
            // Select all label elements within the 'article-issues'
            const options = module.querySelectorAll(
                '.cmswt-Filter-article-issues .ais-RefinementList-labelText',
            );

            if (options.length === 0) {
                return;
            }

            // Loop through each selected option element.
            options.forEach((option) => {
                // Extract the last two words
                const updatedText = extractLastTwoWords(option.textContent);

                option.textContent = updatedText;
            });
        }

        /**
         * Replaces article issue terms in HTML elements by extracting the last two words from the category label.
         *
         * This function fetches the current refinements for 'article-issues' and replaces the content of HTML elements
         * that match the refinement by extracting the last two words.
         */
        function modifyArticleIssuesTermsText() {
            // Retrieve refinements for 'article-issues'
            const articleIssuesRefinements =
                swtInstance.helper.getRefinements('article-issues');

            if (articleIssuesRefinements.length === 0) {
                return;
            }

            const refinements = module.querySelectorAll(
                '.ais-CurrentRefinements-categoryLabel',
            );

            // Loop through each element
            refinements.forEach(function (item) {
                const categoryLabel = item.textContent;

                // Loop through each article-issue refinement and check if its value exists in the category label.
                articleIssuesRefinements.forEach((refinement) => {
                    if (categoryLabel.indexOf(refinement.value) !== -1) {
                        item.textContent = extractLastTwoWords(categoryLabel);
                    }
                });
            });
        }

        /**
         * Extracts the last two words from the input string.
         *
         * @param {string} inputString - The string from which the last two words are extracted.
         * @returns {string} - The last two words from the input string.
         *
         * @example
         * extractLastTwoWords('Issue 70 - April 2023');
         * // returns 'April 2023'
         */
        function extractLastTwoWords(inputString) {
            // Regular expression to match the last two words
            const match = inputString.match(/(\w+\s+\w+)$/);

            // Check if match is successful and return the result
            if (match && match[1]) {
                return match[1];
            }

            return '';
        }

        /**
         * initialize the module
         */
        (function () {
            saveQueryParams();

            mutableTerms = JSON.parse(module.dataset.mutable_terms) ?? {};
            immutableTerms = JSON.parse(module.dataset.immutable_terms) ?? {};
            defaultQueryTerms = getDefaultQueryTerms();
            cookieTerms = getCookieTerms();

            init_swtInstance();

            // isFirstRender actions
            swtInstance.once('render', initializedActions);
            swtInstance.once('render', setDefaultTerms);
            swtInstance.once('render', initializeCustomDateRange);

            renderActions();

            module.addEventListener('click', moduleClickEventHandler);

            window.addEventListener('resize', resizeEventHandler);
        })();
    }

    /**
     * init instant-search functions for Modules
     */
    (function () {
        const modules = document.querySelectorAll(
            '[data-module=postsOverview1] .cmswt-InstantSearch',
        );
        modules.forEach(function (module) {
            moduleInit(module);
        });
    })();
};
