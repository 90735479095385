function tippyTooltips() {
    tippy('[data-tippy-content]:not(.tippy-click)', {
        duration: 600,
        allowHTML: true,
        animation: 'shift-toward',
        theme: 'project-custom',
    });
    tippy('.tippy-click', {
        duration: 600,
        trigger: 'click',
        allowHTML: true,
        interactive: true,
        animation: 'shift-toward',
        theme: 'project-custom',
    });
}

export default tippyTooltips;
